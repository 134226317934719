.slick-initialized {
  @apply opacity-100 visible;
}

.slick-dots {
  @apply absolute bottom-0 right-0 left-0 flex justify-center w-full mr-6 mb-5;
  @screen sm {
    @apply mb-7;
  }
  @screen xl {
    @apply mb-10;
  }

  li {
    @apply px-3;
  }

  button {
    font-size: 0;
    @apply bg-gray-400 block h-4 w-4 rounded-full outline-none opacity-50;
  }

  .slick-active button {
    @apply opacity-100 bg-white;
  }
}

.slider-home-packages {
  @apply -mx-4;
  @screen lg {
    @apply mx-0;
  }

  .slick-slide {
    @apply mx-4 opacity-100 #{!important};
  }
}
