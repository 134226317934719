a,
button {
  @apply break-words duration-300 ease-in-out outline-none #{!important};
}

.stretched-link {
  &:focus {
    @apply outline-none;
  }

  &::after {
    @apply absolute top-0 right-0 bottom-0 left-0 pointer-events-auto;
    content: "";
  }
}


.nav-link {
  @apply hover:text-fuchsia-500;

  &.active {
    @apply text-fuchsia-700;
  }
}

.check-venue-add {
  &:not(:checked) + label {
    @apply hover:shadow-md hover:border-gray-100 text-gray-400;
  }

  & + label {
    @apply cursor-pointer duration-300 ease-in-out;
  }

  &:checked + label {
    @apply border-purple-800 text-purple-800;
  }
}

[data-toggle="tab"].active {
  @apply text-black border-black;
}


.check-venue-modify {
  @apply cursor-pointer duration-300 ease-in-out;

  & + div {
    @apply border-transparent;
  }

  &:not(:checked):hover + div {
    @apply shadow-lg border-gray-100;
  }

  &:checked + div {
    @apply border-purple-800;
  }
}

.menu-item.active {
  @apply bg-fuchsia-600 text-white;
}

.jssocials-share {
  @apply m-0;

  .jssocials-share-link {
    height: 50px;
    width: 50px;
    @apply rounded-none;
  }

  .jssocials-share-logo {
    margin-top: 5px;
    @apply mx-auto;
  }
}
