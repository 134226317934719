.container {
  @apply mx-auto;
}

.h-single {
  @screen lg {
    height: calc(100vh - 200px);
  }
}

.h-container {
  @screen lg {
    min-height: calc(100vh - 295px);
  }
}

.slick-slide {
  @apply z-10 #{!important};
}

.slick-list {
  @apply p-0 #{!important};

  .slick-slide:not(.slick-current) {
    @apply opacity-50
  }
}

.app-container {
  margin-top: 110px;
  @screen sm {
    margin-top: 112px;
  }
  @screen md {
    margin-top: 108px;
  }
  @screen lg {
    margin-top: 112px;
  }
  @screen xl {
    margin-top: 117px;
  }
  @screen 2xl {
    margin-top: 120px;
  }
}

#menu {
  @apply opacity-0;

  &:not(.active) {
    @apply hidden;
  }

  &.active {
    @apply block;
    animation: fadeIn .3s forwards;
  }
}

.auth-container-mobile {
  min-height: calc(100vh - 240px);
}

.about-image {
  @apply w-8/12;
  @screen sm {
    @apply w-3/5;
  }
  @screen lg {
    width: 526px;
  }
  @screen xl {
    width: 746px;
  }
}

.about-svg {
  @apply w-2/12;
  @screen sm {
    @apply w-1/5;
  }
  @screen lg {
    width: calc(50% - 263px);
  }
  @screen xl {
    width: calc(50% - 373px);
  }
}

.about-svg-contact  {
  @apply w-2/12;
  @screen sm {
    @apply w-1/5;
  }
  @screen lg {
    width: calc(50% - 170px);
  }
  @screen xl {
    @apply w-5/12;
  }
}

.account-input-user,
.account-input-package {
  &:not(.active) {
    @apply p-0 border-none disabled pointer-events-none;
  }

  &.active {
    @apply py-2 px-6 border border-gray-400 rounded-full;
  }
}

.text-disabled {
  @apply text-gray-300;
}

.legals-single {
  p {
    @apply mb-4;
  }
}

textarea {
  resize: none;
}
