@mixin button($bg, $color, $border, $hover-bg, $hover-color, $hover-border) {
  transition: all 0.5s;
  @apply #{$bg} #{$color} border #{$border};

  &:hover {
    transition: all 0.5s;
    @apply #{$hover-bg} #{$hover-color} #{$hover-border};
  }
}

.btn {
  @apply cursor-pointer text-center rounded-lg;

  &.disabled {
    @apply pointer-events-none #{!important};
  }

  &:hover {
    @apply no-underline;
  }

  &:focus,
  &:active,
  &:visited {
    @apply shadow-none outline-none;
  }

  &.btn-fuchsia {
    @include button("bg-fuchsia-700", "text-white", "border-fuchsia-700", "bg-fuchsia-800", "text-white", "border-fuchsia-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-purple {
    @include button("bg-purple-700", "text-white", "border-purple-700", "bg-purple-800", "text-white", "border-purple-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-blue {
    @include button("bg-blue-700", "text-white", "border-blue-700", "bg-blue-800", "text-white", "border-blue-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-red {
    @include button("bg-red-700", "text-white", "border-red-700", "bg-red-800", "text-white", "border-red-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-green {
    @include button("bg-green-700", "text-white", "border-green-700", "bg-green-800", "text-white", "border-green-800");

    &.disabled {
      @apply bg-gray-200 text-gray-400 border-gray-200;
    }
  }

  &.btn-outline-fuchsia {
    @include button("bg-transparent", "text-fuchsia-700", "border-fuchsia-700", "bg-fuchsia-800", "text-white", "border-fuchsia-800");

    &.disabled {
      @apply bg-transparent text-fuchsia-400 border-fuchsia-300;
    }
  }

  &.btn-outline-purple {
    @include button("bg-transparent", "text-purple-700", "border-purple-700", "bg-purple-800", "text-white", "border-purple-800");

    &.disabled {
      @apply bg-transparent text-purple-400 border-purple-300;
    }
  }

  &.btn-outline-gray {
    @include button("bg-transparent", "text-purple-800", "border-gray-400", "bg-transparent", "text-gray-500", "border-gray-400");

    &.disabled {
      @apply bg-transparent text-gray-300 border-gray-200;
    }
  }
}
